const strictPostcodeRegex = /^[A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}$/
const postcodeRegex = /^[A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}/

export const isValidPostalCode = (input: string): boolean =>
  strictPostcodeRegex.test(input)

export const sanitisePartialPostalCode = (input: string): string => {
  const formattedInput = input.toUpperCase()
  const match = postcodeRegex.exec(formattedInput)
  return match ? match[0] : formattedInput
}
